@charset "UTF-8";
.index-banner {
  height: calc(100vh - 1.1rem);
}

@media (max-width: 1024px) {
  .index-banner {
    height: calc(100vh - 1.4rem);
  }
}

@media (max-width: 991px) {
  .index-banner {
    height: calc(100vh - 1.6rem);
  }
}

@media (max-width: 767px) {
  .index-banner {
    height: calc(100vh - 1.1rem);
  }
}

.index-banner .swiper {
  height: 100%;
}

.index-banner .swiper .swiper-slide::after {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  pointer-events: none;
}

.index-banner .swiper .swiper-slide .img {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 0;
}

.index-banner .swiper .swiper-slide .img b {
  width: 100%;
  height: 100%;
}

.index-banner .swiper .swiper-slide .img b.pc {
  display: block;
}

@media (max-width: 991px) {
  .index-banner .swiper .swiper-slide .img b.pc {
    display: none;
  }
}

.index-banner .swiper .swiper-slide .img b.mob {
  display: none;
}

@media (max-width: 991px) {
  .index-banner .swiper .swiper-slide .img b.mob {
    display: block;
  }
}

.index-banner .swiper .swiper-slide .video {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
}

.index-banner .swiper .swiper-slide .video video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.index-banner .swiper .swiper-slide .video video.pc {
  display: block;
}

@media (max-width: 991px) {
  .index-banner .swiper .swiper-slide .video video.pc {
    display: none;
  }
}

.index-banner .swiper .swiper-slide .video video.mob {
  display: none;
}

@media (max-width: 991px) {
  .index-banner .swiper .swiper-slide .video video.mob {
    display: block;
  }
}

.index-banner .swiper .swiper-slide .text {
  position: absolute;
  left: 0;
  width: 100%;
  transform: translateY(-50%);
  z-index: 3;
  color: #fff;
  box-sizing: border-box;
  text-align: left;
  top: 50%;
}

@media (max-width: 767px) {
  .index-banner .swiper .swiper-slide .text {
    text-align: center;
  }
}

@media (max-width: 767px) {
  .index-banner .swiper .swiper-slide .text {
    top: 38%;
  }
}

.index-banner .swiper .swiper-slide .text h1 {
  line-height: 1.2;
  opacity: 0;
  transition: all .6s;
  transition-delay: .9s;
  transform: translate3d(1rem, 0, 0);
  margin-bottom: 0.1rem;
  font-size: 0.7rem;
}

@media (max-width: 767px) {
  .index-banner .swiper .swiper-slide .text h1 {
    transform: translate3d(0, 0.6rem, 0);
  }
}

@media (max-width: 1024px) {
  .index-banner .swiper .swiper-slide .text h1 {
    font-size: 0.8rem;
  }
}

@media (max-width: 991px) {
  .index-banner .swiper .swiper-slide .text h1 {
    font-size: 0.9rem;
  }
}

@media (max-width: 767px) {
  .index-banner .swiper .swiper-slide .text h1 {
    font-size: 0.5rem;
  }
}

.index-banner .swiper .swiper-slide .text h2 {
  line-height: 1.2;
  opacity: 0;
  transition: all .6s;
  transition-delay: 1.3s;
  transform: translate3d(1rem, 0, 0);
  margin-bottom: 0.5rem;
  font-size: 0.27rem;
}

@media (max-width: 767px) {
  .index-banner .swiper .swiper-slide .text h2 {
    transform: translate3d(0, 0.6rem, 0);
  }
}

@media (max-width: 1024px) {
  .index-banner .swiper .swiper-slide .text h2 {
    font-size: 0.37rem;
  }
}

@media (max-width: 991px) {
  .index-banner .swiper .swiper-slide .text h2 {
    font-size: 0.47rem;
  }
}

@media (max-width: 767px) {
  .index-banner .swiper .swiper-slide .text h2 {
    font-size: 0.3rem;
  }
}

.index-banner .swiper .swiper-slide .text .link {
  opacity: 0;
  transition: all .6s;
  transition-delay: 1.7s;
  transform: translate3d(1rem, 0, 0);
}

@media (max-width: 767px) {
  .index-banner .swiper .swiper-slide .text .link {
    transform: translate3d(0, 0.6rem, 0);
  }
}

.index-banner .swiper .swiper-slide .text .link a {
  display: inline-block;
  background-color: #005788;
  text-transform: uppercase;
  border-radius: 6px;
  transition: all .3s;
  padding: 0.15rem 0.35rem;
  font-size: 0.16rem;
}

@media (max-width: 1024px) {
  .index-banner .swiper .swiper-slide .text .link a {
    font-size: 0.26rem;
  }
}

@media (max-width: 991px) {
  .index-banner .swiper .swiper-slide .text .link a {
    font-size: 0.36rem;
  }
}

@media (max-width: 767px) {
  .index-banner .swiper .swiper-slide .text .link a {
    font-size: 0.24rem;
  }
}

.index-banner .swiper .swiper-slide .text .link a:hover {
  background-color: #03bef6;
}

.index-banner .swiper .swiper-slide.swiper-slide-active .text h1, .index-banner .swiper .swiper-slide.swiper-slide-active .text h2, .index-banner .swiper .swiper-slide.swiper-slide-active .text .link {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

@media (max-width: 1024px) {
  .index-banner .swiper .swiper-btn {
    top: 70%;
  }
}

.index-banner .swiper .swiper-btn .swiper-button-next {
  right: 0.4rem;
}

.index-banner .swiper .swiper-btn .swiper-button-prev {
  left: 0.4rem;
}

.index-banner .swiper .swiper-pagination {
  bottom: 0.5rem;
}

.index-banner .swiper:hover .swiper-btn {
  opacity: 1;
}

.index-title {
  color: #000;
  margin-bottom: 0.5rem;
  font-size: 0.5rem;
}

@media (max-width: 767px) {
  .index-title {
    margin-bottom: 0.3rem;
  }
}

@media (max-width: 1024px) {
  .index-title {
    font-size: 0.6rem;
  }
}

@media (max-width: 991px) {
  .index-title {
    font-size: 0.7rem;
  }
}

@media (max-width: 767px) {
  .index-title {
    font-size: 0.54rem;
  }
}

.section1 {
  padding-top: 1.1rem;
  padding-bottom: 1.1rem;
}

@media (max-width: 767px) {
  .section1 {
    padding-top: 0.6rem;
  }
}

@media (max-width: 767px) {
  .section1 {
    padding-bottom: 0.6rem;
  }
}

.section1.more .cont {
  overflow-x: auto;
  padding-bottom: 0.1rem;
  /*滚动条样式*/
}

.section1.more .cont::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

.section1.more .cont::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: rgba(189, 189, 189, 0.6);
}

.section1.more .cont::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0);
  border-radius: 0;
  background: rgba(0, 0, 0, 0);
}

.section1.more .cont ul {
  width: auto;
}

.section1.more .cont ul .two {
  width: calc(20% - .1rem);
}

@media (max-width: 991px) {
  .section1.more .cont ul .two {
    width: calc(50% - .1rem);
  }
}

@media (max-width: 767px) {
  .section1.more .cont ul .two {
    width: calc(100% - .1rem);
  }
}

.section1 .cont ul {
  align-items: stretch;
  justify-content: flex-start;
  flex-wrap: nowrap;
  height: 26vw;
  width: calc(100% + .1rem);
  display: flex;
}

@media (max-width: 991px) {
  .section1 .cont ul {
    flex-wrap: wrap;
  }
}

@media (max-width: 1024px) {
  .section1 .cont ul {
    height: 36vw;
  }
}

@media (max-width: 991px) {
  .section1 .cont ul {
    height: auto;
  }
}

.section1 .cont ul li {
  position: relative;
  flex-shrink: 0;
  background-color: #c9dfeb;
  transition: all .3s;
  padding: 0.25rem;
  border-radius: 0.12rem;
}

.section1 .cont ul li:hover {
  box-shadow: 0 0 0.1rem rgba(0, 0, 0, 0.05);
  filter: brightness(105%);
}

.section1 .cont ul li .block-link {
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
}

.section1 .cont ul li .link {
  position: absolute;
  z-index: 1;
  top: 0.25rem;
  left: 0.25rem;
}

.section1 .cont ul li .title {
  color: #000;
  position: relative;
  z-index: 1;
  margin-bottom: 0.1rem;
  font-size: 0.22rem;
}

@media (max-width: 1024px) {
  .section1 .cont ul li .title {
    font-size: 0.32rem;
  }
}

@media (max-width: 991px) {
  .section1 .cont ul li .title {
    font-size: 0.46rem;
  }
}

@media (max-width: 767px) {
  .section1 .cont ul li .title {
    font-size: 0.32rem;
  }
}

.section1 .cont ul li .sub {
  position: relative;
  z-index: 1;
  font-size: 0.16rem;
}

@media (max-width: 1024px) {
  .section1 .cont ul li .sub {
    font-size: 0.24rem;
  }
}

@media (max-width: 991px) {
  .section1 .cont ul li .sub {
    font-size: 0.34rem;
  }
}

@media (max-width: 767px) {
  .section1 .cont ul li .sub {
    font-size: 0.28rem;
  }
}

.section1 .cont ul li .sub > div:not(:last-child) {
  margin-bottom: 0.05rem;
}

.section1 .cont ul li .sub a {
  display: inline-block;
  transition: all .3s;
  color: rgba(0, 0, 0, 0.7);
}

.section1 .cont ul li .sub a:hover {
  color: #000;
}

.section1 .cont ul li .icon {
  display: block;
  position: absolute;
  pointer-events: none;
  width: auto;
  height: 0.9rem;
  bottom: 0.25rem;
  right: 0.25rem;
}

@media (max-width: 991px) {
  .section1 .cont ul li .icon {
    height: 1.3rem;
  }
}

@media (max-width: 767px) {
  .section1 .cont ul li .icon {
    height: 1rem;
  }
}

@media (max-width: 991px) {
  .section1 .cont ul li .icon {
    bottom: 0.35rem;
  }
}

@media (max-width: 991px) {
  .section1 .cont ul li .icon {
    right: 0.35rem;
  }
}

.section1 .cont ul .one {
  flex-shrink: 0;
  margin-right: 0.1rem;
  margin-bottom: 0;
  width: calc(20% - .1rem);
  height: 100%;
}

@media (max-width: 767px) {
  .section1 .cont ul .one {
    margin-right: 0;
  }
}

@media (max-width: 767px) {
  .section1 .cont ul .one {
    margin-bottom: 0.1rem;
  }
}

@media (max-width: 991px) {
  .section1 .cont ul .one {
    width: calc(50% - .1rem);
  }
}

@media (max-width: 767px) {
  .section1 .cont ul .one {
    width: calc(100% - .1rem);
  }
}

@media (max-width: 991px) {
  .section1 .cont ul .one {
    height: 46vw;
  }
}

@media (max-width: 767px) {
  .section1 .cont ul .one {
    height: 100vw;
  }
}

.section1 .cont ul .one li {
  width: 100%;
  height: calc(50% - .05rem);
}

.section1 .cont ul .one li:nth-child(1) {
  margin-bottom: 0.1rem;
}

.section1 .cont ul .one li:nth-child(2) {
  background-color: #dce7ed;
}

.section1 .cont ul .two {
  flex-shrink: 0;
  margin-right: 0.1rem;
  height: 100%;
  width: calc(20% - .1rem);
}

@media (max-width: 767px) {
  .section1 .cont ul .two {
    margin-right: 0;
  }
}

@media (max-width: 991px) {
  .section1 .cont ul .two {
    height: 46vw;
  }
}

@media (max-width: 767px) {
  .section1 .cont ul .two {
    height: 100vw;
  }
}

@media (max-width: 991px) {
  .section1 .cont ul .two {
    width: calc(50% - .1rem);
  }
}

@media (max-width: 767px) {
  .section1 .cont ul .two {
    width: calc(100% - .1rem);
  }
}

.section1 .cont ul .two li {
  width: 100%;
  height: calc(50% - .05rem);
}

.section1 .cont ul .two li:nth-child(1) {
  background-color: #dce7ed;
  margin-bottom: 0.1rem;
}

.section1 .cont ul .two li:nth-child(2) {
  background-color: #d9e8f1;
}

.section1 .cont ul .three {
  flex-shrink: 0;
  align-items: stretch;
  justify-content: flex-start;
  margin-top: 0;
  margin-right: 0.1rem;
  height: 100%;
  width: calc(20% - .1rem);
}

@media (max-width: 767px) {
  .section1 .cont ul .three {
    display: flex;
  }
}

@media (max-width: 991px) {
  .section1 .cont ul .three {
    margin-top: 0.1rem;
  }
}

@media (max-width: 767px) {
  .section1 .cont ul .three {
    margin-right: 0;
  }
}

@media (max-width: 991px) {
  .section1 .cont ul .three {
    height: 46vw;
  }
}

@media (max-width: 767px) {
  .section1 .cont ul .three {
    height: 35vw;
  }
}

@media (max-width: 991px) {
  .section1 .cont ul .three {
    width: calc(33.33% - .1rem);
  }
}

@media (max-width: 767px) {
  .section1 .cont ul .three {
    width: 100%;
  }
}

.section1 .cont ul .three li {
  margin-right: 0;
  width: 100%;
  height: calc(50% - .05rem);
}

@media (max-width: 767px) {
  .section1 .cont ul .three li {
    margin-right: 0.1rem;
  }
}

@media (max-width: 767px) {
  .section1 .cont ul .three li {
    width: calc(50% - .1rem);
  }
}

@media (max-width: 767px) {
  .section1 .cont ul .three li {
    height: 100%;
  }
}

.section1 .cont ul .three li:nth-child(1) {
  margin-bottom: 0.1rem;
}

.section1 .cont ul .three:nth-child(3) li:nth-child(1) {
  background-color: #ebf3f8;
}

.section1 .cont ul .three:nth-child(3) li:nth-child(2) {
  background-color: #f0f3f4;
}

.section1 .cont ul .three:nth-child(4) li:nth-child(1) {
  background-color: #e3ebef;
}

.section1 .cont ul .three:nth-child(4) li:nth-child(2) {
  background-color: #e9eff3;
}

.section1 .cont ul .three:nth-child(5) li:nth-child(1) {
  background-color: #ebf0f2;
}

.section1 .cont ul .three:nth-child(5) li:nth-child(2) {
  background-color: #e5eef3;
}

.section1 .cont ul .three:nth-child(6) li:nth-child(1) {
  background-color: #dce7ed;
  margin-bottom: 0.1rem;
}

.section1 .cont ul .three:nth-child(6) li:nth-child(2) {
  background-color: #d9e8f1;
}

.section1 .cont ul .three:nth-child(7) li:nth-child(1) {
  background-color: #ebf3f8;
}

.section1 .cont ul .three:nth-child(7) li:nth-child(2) {
  background-color: #f0f3f4;
}

.section1 .cont ul .three:nth-child(8) li:nth-child(1) {
  background-color: #e3ebef;
}

.section1 .cont ul .three:nth-child(8) li:nth-child(2) {
  background-color: #e9eff3;
}

.section1 .cont ul .three:nth-child(9) li:nth-child(1) {
  background-color: #ebf0f2;
}

.section1 .cont ul .three:nth-child(9) li:nth-child(2) {
  background-color: #e5eef3;
}

.section2 .index-title {
  margin-bottom: 0.4rem;
}

@media (max-width: 767px) {
  .section2 .index-title {
    margin-bottom: 0.3rem;
  }
}

.section2 .cont {
  position: relative;
  padding-top: 1.3rem;
  padding-bottom: 1.3rem;
}

@media (max-width: 767px) {
  .section2 .cont {
    padding-top: 0.6rem;
  }
}

@media (max-width: 767px) {
  .section2 .cont {
    padding-bottom: 0.6rem;
  }
}

.section2 .cont .bg-swiper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.section2 .cont .bg-swiper .swiper-slide::after {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  pointer-events: none;
}

.section2 .cont .bg-swiper .swiper-slide .img {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 0;
}

.section2 .cont .bg-swiper .swiper-slide .img b {
  width: 100%;
  height: 100%;
}

.section2 .cont .bg-swiper .swiper-slide .img b.pc {
  display: block;
}

@media (max-width: 991px) {
  .section2 .cont .bg-swiper .swiper-slide .img b.pc {
    display: none;
  }
}

.section2 .cont .bg-swiper .swiper-slide .img b.mob {
  display: none;
}

@media (max-width: 991px) {
  .section2 .cont .bg-swiper .swiper-slide .img b.mob {
    display: block;
  }
}

.section2 .cont .bg-swiper .swiper-slide .video {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
}

.section2 .cont .bg-swiper .swiper-slide .video video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.section2 .cont .bg-swiper .swiper-slide .video video.pc {
  display: block;
}

@media (max-width: 991px) {
  .section2 .cont .bg-swiper .swiper-slide .video video.pc {
    display: none;
  }
}

.section2 .cont .bg-swiper .swiper-slide .video video.mob {
  display: none;
}

@media (max-width: 991px) {
  .section2 .cont .bg-swiper .swiper-slide .video video.mob {
    display: block;
  }
}

.section2 .cont .container {
  align-items: stretch;
  justify-content: space-between;
  color: #fff;
  display: flex;
}

@media (max-width: 991px) {
  .section2 .cont .container {
    display: block;
  }
}

.section2 .cont .container .swiper-tab {
  flex-shrink: 0;
  background-color: rgba(0, 0, 0, 0.4);
  box-sizing: border-box;
  align-items: center;
  justify-content: space-between;
  align-content: center;
  flex-wrap: wrap;
  display: flex;
  border-radius: 0.12rem;
  width: 49%;
  min-height: 27.5vw;
  padding-left: 1.2rem;
  padding-right: 0.2rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  margin-bottom: 0;
}

@media (max-width: 991px) {
  .section2 .cont .container .swiper-tab {
    width: 100%;
  }
}

@media (max-width: 991px) {
  .section2 .cont .container .swiper-tab {
    min-height: auto;
  }
}

@media (max-width: 767px) {
  .section2 .cont .container .swiper-tab {
    padding-left: 0.4rem;
  }
}

@media (max-width: 767px) {
  .section2 .cont .container .swiper-tab {
    padding-right: 0;
  }
}

@media (max-width: 991px) {
  .section2 .cont .container .swiper-tab {
    padding-top: 0.8rem;
  }
}

@media (max-width: 767px) {
  .section2 .cont .container .swiper-tab {
    padding-top: 0.2rem;
  }
}

@media (max-width: 991px) {
  .section2 .cont .container .swiper-tab {
    padding-bottom: 0.8rem;
  }
}

@media (max-width: 767px) {
  .section2 .cont .container .swiper-tab {
    padding-bottom: 0.2rem;
  }
}

@media (max-width: 991px) {
  .section2 .cont .container .swiper-tab {
    margin-bottom: 1rem;
  }
}

@media (max-width: 767px) {
  .section2 .cont .container .swiper-tab {
    margin-bottom: 0.5rem;
  }
}

.section2 .cont .container .swiper-tab > div {
  flex-shrink: 0;
  box-sizing: border-box;
  position: relative;
  cursor: pointer;
  transition: all .3s;
  font-size: 0.18rem;
  padding-left: 0.25rem;
  width: 50%;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  margin-top: 0.2rem;
  margin-bottom: 0.2rem;
}

@media (max-width: 1024px) {
  .section2 .cont .container .swiper-tab > div {
    font-size: 0.28rem;
  }
}

@media (max-width: 991px) {
  .section2 .cont .container .swiper-tab > div {
    font-size: 0.34rem;
  }
}

@media (max-width: 767px) {
  .section2 .cont .container .swiper-tab > div {
    font-size: 0.28rem;
  }
}

@media (max-width: 767px) {
  .section2 .cont .container .swiper-tab > div {
    padding-left: 0.15rem;
  }
}

@media (max-width: 991px) {
  .section2 .cont .container .swiper-tab > div {
    width: 33.33%;
  }
}

@media (max-width: 767px) {
  .section2 .cont .container .swiper-tab > div {
    width: 50%;
  }
}

@media (max-width: 767px) {
  .section2 .cont .container .swiper-tab > div {
    padding-top: 0.1rem;
  }
}

@media (max-width: 767px) {
  .section2 .cont .container .swiper-tab > div {
    padding-bottom: 0.1rem;
  }
}

@media (max-width: 767px) {
  .section2 .cont .container .swiper-tab > div {
    margin-top: 0;
  }
}

@media (max-width: 767px) {
  .section2 .cont .container .swiper-tab > div {
    margin-bottom: 0;
  }
}

.section2 .cont .container .swiper-tab > div::after {
  content: '';
  display: block;
  background-color: #fd8454;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 0.04rem;
  height: 0.3rem;
}

@media (max-width: 1024px) {
  .section2 .cont .container .swiper-tab > div::after {
    width: 2px;
  }
}

@media (max-width: 1024px) {
  .section2 .cont .container .swiper-tab > div::after {
    height: 20px;
  }
}

.section2 .cont .container .swiper-tab > div.active {
  font-family: 'roboto_b';
}

.section2 .cont .container .text-swiper {
  flex-shrink: 0;
  border: solid #fff;
  border-top: none;
  border-radius: 0.12rem;
  border-width: 2px;
  width: 49%;
  height: auto;
}

@media (max-width: 991px) {
  .section2 .cont .container .text-swiper {
    width: 100%;
  }
}

@media (max-width: 991px) {
  .section2 .cont .container .text-swiper {
    height: 40vw;
  }
}

@media (max-width: 767px) {
  .section2 .cont .container .text-swiper {
    height: 100vw;
  }
}

.section2 .cont .container .text-swiper .swiper {
  overflow: visible;
  height: 100%;
}

.section2 .cont .container .text-swiper .swiper .swiper-slide {
  box-sizing: border-box;
  padding-top: 1.4rem;
  padding-left: 1.4rem;
  padding-right: 0.7rem;
  padding-bottom: 1.1rem;
}

@media (max-width: 767px) {
  .section2 .cont .container .text-swiper .swiper .swiper-slide {
    padding-top: 0.8rem;
  }
}

@media (max-width: 767px) {
  .section2 .cont .container .text-swiper .swiper .swiper-slide {
    padding-left: 0.7rem;
  }
}

@media (max-width: 767px) {
  .section2 .cont .container .text-swiper .swiper .swiper-slide {
    padding-right: 0.5rem;
  }
}

@media (max-width: 767px) {
  .section2 .cont .container .text-swiper .swiper .swiper-slide {
    padding-bottom: 1rem;
  }
}

.section2 .cont .container .text-swiper .swiper .swiper-slide .title {
  display: flex;
  align-items: flex-start;
  position: absolute;
  top: 0;
  left: -2px;
  right: -2px;
}

.section2 .cont .container .text-swiper .swiper .swiper-slide .title::before, .section2 .cont .container .text-swiper .swiper .swiper-slide .title::after {
  content: '';
  display: block;
  height: .1rem;
  pointer-events: none;
  border: solid #fff;
  border-bottom: none;
  border-width: 2px;
}

.section2 .cont .container .text-swiper .swiper .swiper-slide .title::before {
  flex-shrink: 0;
  border-right: none;
  border-top-left-radius: 0.12rem;
  width: 0.65rem;
}

@media (max-width: 1024px) {
  .section2 .cont .container .text-swiper .swiper .swiper-slide .title::before {
    width: 0.8rem;
  }
}

@media (max-width: 991px) {
  .section2 .cont .container .text-swiper .swiper .swiper-slide .title::before {
    width: 1.2rem;
  }
}

@media (max-width: 767px) {
  .section2 .cont .container .text-swiper .swiper .swiper-slide .title::before {
    width: 0.5rem;
  }
}

.section2 .cont .container .text-swiper .swiper .swiper-slide .title::after {
  flex-grow: 1;
  border-left: none;
  border-top-right-radius: 0.12rem;
}

.section2 .cont .container .text-swiper .swiper .swiper-slide .title span {
  display: block;
  flex-shrink: 0;
  line-height: 1;
  transform: translateY(-50%);
  font-size: 0.3rem;
  margin-left: 0.13rem;
  margin-right: 0.13rem;
}

@media (max-width: 1024px) {
  .section2 .cont .container .text-swiper .swiper .swiper-slide .title span {
    font-size: 0.4rem;
  }
}

@media (max-width: 991px) {
  .section2 .cont .container .text-swiper .swiper .swiper-slide .title span {
    font-size: 0.5rem;
  }
}

@media (max-width: 767px) {
  .section2 .cont .container .text-swiper .swiper .swiper-slide .title span {
    font-size: 0.44rem;
  }
}

.section2 .cont .container .text-swiper .swiper .swiper-slide .text {
  position: relative;
  line-height: 1.75;
  font-size: 0.2rem;
}

@media (max-width: 1024px) {
  .section2 .cont .container .text-swiper .swiper .swiper-slide .text {
    font-size: 0.3rem;
  }
}

@media (max-width: 991px) {
  .section2 .cont .container .text-swiper .swiper .swiper-slide .text {
    font-size: 0.4rem;
  }
}

@media (max-width: 767px) {
  .section2 .cont .container .text-swiper .swiper .swiper-slide .text {
    font-size: 0.32rem;
  }
}

.section2 .cont .container .text-swiper .swiper .swiper-slide .text::before {
  content: '';
  display: block;
  background: url(./images/index_icon_dh.png) no-repeat bottom center;
  background-size: contain;
  position: absolute;
  top: 0;
  left: 0;
  transform: translate3d(-140%, -100%, 0);
  width: 0.39rem;
  height: 0.29rem;
}

.section2 .cont .container .text-swiper .swiper .swiper-slide .sub-link {
  margin-top: 0.3rem;
  font-size: 0.16rem;
}

@media (max-width: 1024px) {
  .section2 .cont .container .text-swiper .swiper .swiper-slide .sub-link {
    font-size: 0.26rem;
  }
}

@media (max-width: 991px) {
  .section2 .cont .container .text-swiper .swiper .swiper-slide .sub-link {
    font-size: 0.32rem;
  }
}

@media (max-width: 767px) {
  .section2 .cont .container .text-swiper .swiper .swiper-slide .sub-link {
    font-size: 0.28rem;
  }
}

.section2 .cont .container .text-swiper .swiper .swiper-slide .sub-link a {
  display: inline-block;
  border-radius: 6px;
  background-color: #fff;
  color: #333;
  transition: all .3s;
  padding: 0.03rem 0.16rem;
  margin-right: 1px;
  margin-bottom: 5px;
}

.section2 .cont .container .text-swiper .swiper .swiper-slide .sub-link a:hover {
  background-color: #03bef6;
  color: #fff;
}

.section2 .cont .container .text-swiper .swiper .swiper-slide .more {
  position: absolute;
  z-index: 2;
  bottom: 0.6rem;
  right: 0.8rem;
}

@media (max-width: 767px) {
  .section2 .cont .container .text-swiper .swiper .swiper-slide .more {
    bottom: 0.3rem;
  }
}

@media (max-width: 767px) {
  .section2 .cont .container .text-swiper .swiper .swiper-slide .more {
    right: 0.4rem;
  }
}

.section3 {
  background-color: #e2ebef;
  padding-top: 1rem;
  padding-bottom: 0.95rem;
}

@media (max-width: 767px) {
  .section3 {
    padding-top: 0.6rem;
  }
}

@media (max-width: 767px) {
  .section3 {
    padding-bottom: 0.6rem;
  }
}

.section3 .index-title {
  margin-bottom: 0.4rem;
}

@media (max-width: 767px) {
  .section3 .index-title {
    margin-bottom: 0.3rem;
  }
}

.section3 .cont {
  align-items: stretch;
  justify-content: space-between;
  display: flex;
}

@media (max-width: 767px) {
  .section3 .cont {
    display: block;
  }
}

.section3 .cont .left {
  flex-shrink: 0;
  background-color: #fff;
  border-radius: .12rem;
  overflow: hidden;
  transition: all .3s;
  margin-bottom: 0;
  width: 49%;
}

@media (max-width: 767px) {
  .section3 .cont .left {
    margin-bottom: 0.3rem;
  }
}

@media (max-width: 767px) {
  .section3 .cont .left {
    width: 100%;
  }
}

.section3 .cont .left:hover {
  box-shadow: 0 0 0.2rem rgba(0, 0, 0, 0.1);
}

.section3 .cont .left .img {
  overflow: hidden;
  height: 22.7vw;
}

@media (max-width: 1024px) {
  .section3 .cont .left .img {
    height: 28vw;
  }
}

@media (max-width: 991px) {
  .section3 .cont .left .img {
    height: 33.5vw;
  }
}

@media (max-width: 767px) {
  .section3 .cont .left .img {
    height: 53.5vw;
  }
}

.section3 .cont .left .img a {
  display: block;
  height: 100%;
  transition: all .3s;
}

.section3 .cont .left .img:hover a {
  transform: scale(1.03);
}

.section3 .cont .left .text {
  position: relative;
  padding-left: 0.4rem;
  padding-right: 0.5rem;
  padding-top: 0.3rem;
  padding-bottom: 0.7rem;
}

@media (max-width: 1024px) {
  .section3 .cont .left .text {
    padding-bottom: 0.8rem;
  }
}

@media (max-width: 991px) {
  .section3 .cont .left .text {
    padding-bottom: 0.9rem;
  }
}

.section3 .cont .left .text .tag {
  color: #729eb7;
  font-size: 0.14rem;
}

@media (max-width: 1024px) {
  .section3 .cont .left .text .tag {
    font-size: 0.24rem;
  }
}

@media (max-width: 991px) {
  .section3 .cont .left .text .tag {
    font-size: 0.34rem;
  }
}

@media (max-width: 767px) {
  .section3 .cont .left .text .tag {
    font-size: 0.24rem;
  }
}

.section3 .cont .left .text .title {
  color: #000;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  transition: all .3s;
  margin-top: 0.05rem;
  margin-bottom: 0.05rem;
  font-size: 0.25rem;
}

@media (max-width: 1024px) {
  .section3 .cont .left .text .title {
    font-size: 0.35rem;
  }
}

@media (max-width: 991px) {
  .section3 .cont .left .text .title {
    font-size: 0.45rem;
  }
}

@media (max-width: 767px) {
  .section3 .cont .left .text .title {
    font-size: 0.34rem;
  }
}

.section3 .cont .left .text .title:hover {
  color: #005788;
}

.section3 .cont .left .text .desc {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  height: 0.6rem;
}

@media (max-width: 1024px) {
  .section3 .cont .left .text .desc {
    height: 0.95rem;
  }
}

@media (max-width: 991px) {
  .section3 .cont .left .text .desc {
    height: 1.15rem;
  }
}

@media (max-width: 767px) {
  .section3 .cont .left .text .desc {
    height: 1rem;
  }
}

.section3 .cont .left .text .more {
  position: absolute;
  right: 0.38rem;
  bottom: 0.3rem;
}

.section3 .cont .right {
  flex-shrink: 0;
  width: 49%;
}

@media (max-width: 767px) {
  .section3 .cont .right {
    width: 100%;
  }
}

.section3 .cont .right ul {
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  display: flex;
}

.section3 .cont .right ul li {
  background-color: #fff;
  flex-grow: 1;
  box-sizing: border-box;
  border-radius: .12rem;
  transition: all .3s;
  padding-top: 0.38rem;
  padding-bottom: 0.3rem;
  padding-left: 0.4rem;
  padding-right: 0.4rem;
}

.section3 .cont .right ul li:hover {
  box-shadow: 0 0 0.2rem rgba(0, 0, 0, 0.1);
}

.section3 .cont .right ul li:hover .item-title {
  color: #005788;
}

.section3 .cont .right ul li:not(:last-child) {
  margin-bottom: 0.3rem;
}

.section3 .cont .right ul li .item-tag {
  color: #729eb7;
  font-size: 0.14rem;
}

@media (max-width: 1024px) {
  .section3 .cont .right ul li .item-tag {
    font-size: 0.24rem;
  }
}

@media (max-width: 991px) {
  .section3 .cont .right ul li .item-tag {
    font-size: 0.34rem;
  }
}

@media (max-width: 767px) {
  .section3 .cont .right ul li .item-tag {
    font-size: 0.24rem;
  }
}

.section3 .cont .right ul li .item-title {
  color: #000;
  line-height: 1.3;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  transition: all .3s;
  height: 0.6rem;
  margin-top: 0.1rem;
  margin-bottom: 0.15rem;
  font-size: 0.23rem;
}

@media (max-width: 1024px) {
  .section3 .cont .right ul li .item-title {
    height: 0.9rem;
  }
}

@media (max-width: 991px) {
  .section3 .cont .right ul li .item-title {
    height: 1.15rem;
  }
}

@media (max-width: 767px) {
  .section3 .cont .right ul li .item-title {
    height: 0.9rem;
  }
}

@media (max-width: 1024px) {
  .section3 .cont .right ul li .item-title {
    font-size: 0.33rem;
  }
}

@media (max-width: 991px) {
  .section3 .cont .right ul li .item-title {
    font-size: 0.43rem;
  }
}

@media (max-width: 767px) {
  .section3 .cont .right ul li .item-title {
    font-size: 0.34rem;
  }
}

.section3 .cont .right ul li .item-date {
  color: #333;
  font-size: 0.14rem;
}

@media (max-width: 1024px) {
  .section3 .cont .right ul li .item-date {
    font-size: 0.24rem;
  }
}

@media (max-width: 991px) {
  .section3 .cont .right ul li .item-date {
    font-size: 0.34rem;
  }
}

@media (max-width: 767px) {
  .section3 .cont .right ul li .item-date {
    font-size: 0.24rem;
  }
}
