@import "../../assets/styles/utils";

.index-banner{
  @include res(height,calc(100vh - 1.1rem),(md:calc(100vh - 1.4rem),sm:calc(100vh - 1.6rem),xs:calc(100vh - 1.1rem)));
  .swiper{
    height: 100%;
    .swiper-slide{
      &::after{
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,.3);
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        pointer-events: none;
      }
      .img{
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 0;
        b{
          width: 100%;
          height: 100%;
          &.pc{
            @include res(display,block,(sm:none));
          }
          &.mob{
            @include res(display,none,(sm:block));
          }
        }
      }
      .video{
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 1;
        video{
          width: 100%;
          height: 100%;
          object-fit: cover;
          &.pc{
            @include res(display,block,(sm:none));
          }
          &.mob{
            @include res(display,none,(sm:block));
          }
        }
      }
      .text{
        position: absolute;
        left: 0;
        width: 100%;
        transform: translateY(-50%);
        z-index: 3;
        color: #fff;
        box-sizing: border-box;
        @include res(text-align,left,(xs:center));
        @include res(top,50%,(xs:38%));
        h1{
          line-height: 1.2;
          opacity: 0;
          transition: all .6s;
          transition-delay: .9s;
          @include res(transform,translate3d(1rem,0,0),(xs:translate3d(0,.6rem,0)));
          @include res(margin-bottom,.1rem);
          @include res(font-size, .7rem,(md:.8rem,sm:.9rem,xs:.5rem));
        }
        h2{
          line-height: 1.2;
          opacity: 0;
          transition: all .6s;
          transition-delay: 1.3s;
          @include res(transform,translate3d(1rem,0,0),(xs:translate3d(0,.6rem,0)));
          @include res(margin-bottom,.5rem);
          @include res(font-size, .27rem,(md:.37rem,sm:.47rem,xs:.3rem));
        }
        .link{
          opacity: 0;
          transition: all .6s;
          transition-delay: 1.7s;
          @include res(transform,translate3d(1rem,0,0),(xs:translate3d(0,.6rem,0)));
          a{
            display: inline-block;
            background-color: $color-main;
            text-transform: uppercase;
            border-radius: 6px;
            transition: all .3s;
            @include res(padding, .15rem .35rem);
            @include res(font-size,.16rem,(md:.26rem,sm:.36rem,xs:.24rem));
            &:hover{
              background-color: $color-main-light;
            }
          }
        }
      }
      &.swiper-slide-active{
        .text{
          h1,h2,.link{
            opacity: 1;
            transform: translate3d(0,0,0);
          }
        }
      }
    }
    .swiper-btn{
      @include res(top,null,(md:70%));
      .swiper-button-next{
        @include res(right,.4rem);
      }
      .swiper-button-prev{
        @include res(left,.4rem);
      }
    }
    .swiper-pagination{
      @include res(bottom,.5rem);
    }
    &:hover{
      .swiper-btn{
        opacity: 1;
      }
    }
  }
}

.index-title{
  color: #000;
  @include res(margin-bottom,.5rem,(xs:.3rem));
  @include res(font-size,.5rem,(md:.6rem,sm:.7rem,xs:.54rem));
}

.section1{
  @include res(padding-top,1.1rem,(xs:.6rem));
  @include res(padding-bottom,1.1rem,(xs:.6rem));
  &.more{
    .cont{
      overflow-x: auto;
      @include res(padding-bottom,.1rem);
      /*滚动条样式*/
      &::-webkit-scrollbar {
        width: 4px;
        height: 4px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
        background: rgba(#BDBDBD,.6);
      }
      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px rgba(0,0,0,0);
        border-radius: 0;
        background: rgba(0,0,0,0);
      }
      ul{
        width: auto;
        .two{
          // @include res(width,25.6%,(sm:calc(33.33% - .1rem),xs:calc(100% - .1rem)));
          @include res(width,calc(20% - .1rem),(sm:calc(50% - .1rem),xs:calc(100% - .1rem)));
        }
        .three{
          &:nth-child(3){
            // @include res(margin-top,null,(sm:0,xs:.1rem));
          }
        }
      }
    }
  }
  .cont{
    ul{
      align-items: stretch;
      justify-content: flex-start;
      @include res(flex-wrap,nowrap,(sm:wrap));
      @include res(height,26vw,(md:36vw,sm:auto));
      @include res(width,calc(100% + .1rem));
      @include res(display,flex);
      li{
        position: relative;
        flex-shrink: 0;
        background-color: #c9dfeb;
        transition: all .3s;
        @include res(padding,.25rem);
        @include res(border-radius,.12rem);
        &:hover{
          box-shadow: 0 0 .1rem rgba(0,0,0,.05);
          filter: brightness(105%);
        }
        .block-link{
          display: block;
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          z-index: 0;
        }
        .link{
          position: absolute;
          z-index: 1;
          @include res(top,.25rem);
          @include res(left,.25rem);
        }
        .title{
          color: #000;
          position: relative;
          z-index: 1;
          @include res(margin-bottom,.1rem);
          @include res(font-size,.22rem,(md:.32rem,sm:.46rem,xs:.32rem));
        }
        .sub{
          position: relative;
          z-index: 1;
          @include res(font-size,.16rem,(md:.24rem,sm:.34rem,xs:.28rem));
          >div{
            &:not(:last-child){
              @include res(margin-bottom,.05rem);
            }
          }
          a{
            display: inline-block;
            transition: all .3s;
            color: rgba(0,0,0,.7);
            &:hover{
              color: #000;
            }
          }
        }
        .icon{
          display: block;
          position: absolute;
          pointer-events: none;
          width: auto;
          @include res(height,.9rem,(sm:1.3rem,xs:1rem));
          @include res(bottom,.25rem,(sm:.35rem));
          @include res(right,.25rem,(sm:.35rem));
        }
      }
      .one{
        flex-shrink: 0;
        @include res(margin-right,.1rem,(xs:0));
        @include res(margin-bottom,0,(xs:.1rem));
        // @include res(width,19.5%,(sm:calc(33.33% - .1rem),xs:calc(100% - .1rem)));
        @include res(width,calc(20% - .1rem),(sm:calc(50% - .1rem),xs:calc(100% - .1rem)));
        @include res(height,100%,(sm:46vw,xs:100vw));
        li{
          @include res(width,100%);
          // @include res(height,100%);
          @include res(height,calc(50% - .05rem));
          &:nth-child(1){
            @include res(margin-bottom,.1rem);
          }
          &:nth-child(2){
            background-color: #dce7ed;
          }
        }
      }
      .two{
        flex-shrink: 0;
        @include res(margin-right,.1rem,(xs:0));
        @include res(height,100%,(sm:46vw,xs:100vw));
        // @include res(width,25.6%,(sm:calc(66.66% - .1rem),xs:calc(100% - .1rem)));
        @include res(width,calc(20% - .1rem),(sm:calc(50% - .1rem),xs:calc(100% - .1rem)));
        li{
          @include res(width,100%);
          @include res(height,calc(50% - .05rem));
          &:nth-child(1){
            background-color: #dce7ed;
            @include res(margin-bottom,.1rem);
          }
          &:nth-child(2){
            background-color: #d9e8f1;
          }
        }
      }
      .three{
        flex-shrink: 0;
        align-items: stretch;
        justify-content: flex-start;
        @include res(display,null,(xs:flex));
        @include res(margin-top,0,(sm:.1rem));
        @include res(margin-right,.1rem,(xs:0));
        @include res(height,100%,(sm:46vw,xs:35vw));
        // @include res(width,17.2%,(sm:calc(33.33% - .1rem),xs:100%));
        @include res(width,calc(20% - .1rem),(sm:calc(33.33% - .1rem),xs:100%));
        li{
          @include res(margin-right,0,(xs:.1rem));
          @include res(width,100%,(xs:calc(50% - .1rem)));
          @include res(height,calc(50% - .05rem),(xs:100%));
          &:nth-child(1){
            @include res(margin-bottom,.1rem);
          }
        }
        &:nth-child(3){
          li{
            &:nth-child(1){
              background-color: #ebf3f8;
            }
            &:nth-child(2){
              background-color: #f0f3f4;
            }
          }
        }
        &:nth-child(4){
          li{
            &:nth-child(1){
              background-color: #e3ebef;
            }
            &:nth-child(2){
              background-color: #e9eff3;
            }
          }
        }
        &:nth-child(5){
          li{
            &:nth-child(1){
              background-color: #ebf0f2;
            }
            &:nth-child(2){
              background-color: #e5eef3;
            }
          }
        }
        &:nth-child(6){
          li{
            &:nth-child(1){
              background-color: #dce7ed;
              @include res(margin-bottom,.1rem);
            }
            &:nth-child(2){
              background-color: #d9e8f1;
            }
          }
        }
        &:nth-child(7){
          li{
            &:nth-child(1){
              background-color: #ebf3f8;
            }
            &:nth-child(2){
              background-color: #f0f3f4;
            }
          }
        }
        &:nth-child(8){
          li{
            &:nth-child(1){
              background-color: #e3ebef;
            }
            &:nth-child(2){
              background-color: #e9eff3;
            }
          }
        }
        &:nth-child(9){
          li{
            &:nth-child(1){
              background-color: #ebf0f2;
            }
            &:nth-child(2){
              background-color: #e5eef3;
            }
          }
        }
      }
    }
  }
}
.section2{
  .index-title{
    @include res(margin-bottom,.4rem,(xs:.3rem));
  }
  .cont{
    position: relative;
    @include res(padding-top,1.3rem,(xs:.6rem));
    @include res(padding-bottom,1.3rem,(xs:.6rem));
    .bg-swiper{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
      .swiper-slide{
        &::after{
          content: '';
          display: block;
          width: 100%;
          height: 100%;
          background-color: rgba(0,0,0,.3);
          position: absolute;
          top: 0;
          left: 0;
          z-index: 2;
          pointer-events: none;
        }
        .img{
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          z-index: 0;
          b{
            width: 100%;
            height: 100%;
            &.pc{
              @include res(display,block,(sm:none));
            }
            &.mob{
              @include res(display,none,(sm:block));
            }
          }
        }
        .video{
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          z-index: 1;
          video{
            width: 100%;
            height: 100%;
            object-fit: cover;
            &.pc{
              @include res(display,block,(sm:none));
            }
            &.mob{
              @include res(display,none,(sm:block));
            }
          }
        }
      }
    }
    .container{
      align-items: stretch;
      justify-content: space-between;
      color: #fff;
      @include res(display,flex,(sm:block));
      .swiper-tab{
        flex-shrink: 0;
        background-color: rgba(0,0,0,.4);
        box-sizing: border-box;
        align-items: center;
        justify-content: space-between;
        align-content: center;
        flex-wrap: wrap;
        @include res(display,flex);
        @include res(border-radius,.12rem);
        @include res(width,49%,(sm:100%));
        @include res(min-height,27.5vw,(sm:auto));
        @include res(padding-left,1.2rem,(xs:.4rem));
        @include res(padding-right,.2rem,(xs:0));
        @include res(padding-top,.5rem,(sm:.8rem,xs:.2rem));
        @include res(padding-bottom,.5rem,(sm:.8rem,xs:.2rem));
        @include res(margin-bottom,0,(sm:1rem,xs:.5rem));
        >div{
          flex-shrink: 0;
          box-sizing: border-box;
          position: relative;
          cursor: pointer;
          transition: all .3s;
          @include res(font-size,.18rem,(md:.28rem,sm:.34rem,xs:.28rem));
          @include res(padding-left,.25rem,(xs:.15rem));
          @include res(width,50%,(sm:33.33%,xs:50%));
          @include res(padding-top,.2rem,(xs:.1rem));
          @include res(padding-bottom,.2rem,(xs:.1rem));
          @include res(margin-top,.2rem,(xs:0));
          @include res(margin-bottom,.2rem,(xs:0));
          &::after{
            content: '';
            display: block;
            background-color: #fd8454;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            @include res(width,.04rem,(md:2px));
            @include res(height,.3rem,(md:20px));
          }
          &.active{
            font-family: 'roboto_b';
          }
        }
      }
      .text-swiper{
        flex-shrink: 0;
        border: solid #fff;
        border-top: none;
        @include res(border-radius,.12rem);
        @include res(border-width, 2px);
        @include res(width,49%,(sm:100%));
        @include res(height,auto,(sm:40vw,xs:100vw));
        .swiper{
          overflow: visible;
          height: 100%;
          .swiper-slide{
            box-sizing: border-box;
            @include res(padding-top,1.4rem,(xs:.8rem));
            @include res(padding-left,1.4rem,(xs:.7rem));
            @include res(padding-right,.7rem,(xs:.5rem));
            @include res(padding-bottom,1.1rem,(xs:1rem));
            .title{
              display: flex;
              align-items: flex-start;
              position: absolute;
              top: 0;
              @include res(left, -2px);
              @include res(right, -2px);
              &::before,&::after{
                content: '';
                display: block;
                height: .1rem;
                pointer-events: none;
                border: solid #fff;
                border-bottom: none;
                @include res(border-width,2px);
              }
              &::before{
                flex-shrink: 0;
                border-right: none;
                @include res(border-top-left-radius,.12rem);
                @include res(width,.65rem,(md:.8rem,sm:1.2rem,xs:.5rem));
              }
              &::after{
                flex-grow: 1;
                border-left: none;
                @include res(border-top-right-radius,.12rem);
              }
              span{
                display: block;
                flex-shrink: 0;
                line-height: 1;
                transform: translateY(-50%);
                @include res(font-size,.3rem,(md:.4rem,sm:.5rem,xs:.44rem));
                @include res(margin-left,.13rem);
                @include res(margin-right,.13rem);
              }
            }
            .text{
              position: relative;
              line-height: 1.75;
              @include res(font-size,.2rem,(md:.3rem,sm:.4rem,xs:.32rem));
              &::before{
                content: '';
                display: block;
                background: url(./images/index_icon_dh.png) no-repeat bottom center;
                background-size: contain;
                position: absolute;
                top: 0;
                left: 0;
                transform: translate3d(-140%, -100%, 0);
                @include res(width,.39rem);
                @include res(height,.29rem);
              }
            }
            .sub-link{
              @include res(margin-top,.3rem);
              @include res(font-size,.16rem,(md:.26rem,sm:.32rem,xs:.28rem));
              a{
                display: inline-block;
                border-radius: 6px;
                background-color: #fff;
                color: #333;
                transition: all .3s;
                @include res(padding,.03rem .16rem);
                @include res(margin-right,1px);
                @include res(margin-bottom,5px);
                &:hover{
                  background-color: $color-main-light;
                  color: #fff;
                }
              }
            }
            .more{
              position: absolute;
              z-index: 2;
              @include res(bottom,.6rem,(xs:.3rem));
              @include res(right,.8rem,(xs:.4rem));
            }
          }
        }
      }
    }
  }
}
.section3{
  background-color: #e2ebef;
  @include res(padding-top,1rem,(xs:.6rem));
  @include res(padding-bottom,.95rem,(xs:.6rem));
  .index-title{
    @include res(margin-bottom,.4rem,(xs:.3rem));
  }
  .cont{
    align-items: stretch;
    justify-content: space-between;
    @include res(display,flex,(xs:block));
    .left{
      flex-shrink: 0;
      background-color: #fff;
      border-radius: .12rem;
      overflow: hidden; 
      transition: all .3s;
      @include res(margin-bottom,0,(xs:.3rem));
      @include res(width,49%,(xs:100%));
      &:hover{
        box-shadow: 0 0 .2rem rgba(0,0,0,.1);
      }
      .img{
        overflow: hidden;
        @include res(height,22.7vw,(md:28vw,sm:33.5vw,xs:53.5vw));
        a{
          display: block;
          height: 100%;
          transition: all .3s;
        }
        &:hover{
          a{
            transform: scale(1.03);
          }
        }
      }
      .text{
        position: relative;
        @include res(padding-left,.4rem);
        @include res(padding-right,.5rem);
        @include res(padding-top,.3rem);
        @include res(padding-bottom,.7rem,(md:.8rem,sm:.9rem));
        .tag{
          color: #729eb7;
          @include res(font-size,.14rem,(md:.24rem,sm:.34rem,xs:.24rem));
        }
        .title{
          color: #000;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          transition: all .3s;
          @include res(margin-top, .05rem);
          @include res(margin-bottom, .05rem);
          @include res(font-size,.25rem,(md:.35rem,sm:.45rem,xs:.34rem));
          &:hover{
            color: $color-main;
          }
        }
        .desc{
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
          @include res(height,.6rem,(md:.95rem,sm:1.15rem,xs:1rem));
        }
        .more{
          position: absolute;
          @include res(right,.38rem);
          @include res(bottom,.3rem);
        }
      }
    }
    .right{
      flex-shrink: 0;
      @include res(width,49%,(xs:100%));
      ul{
        height: 100%;
        flex-direction: column;
        justify-content: space-between;
        @include res(display,flex);
        li{
          background-color: #fff;
          flex-grow: 1;
          box-sizing: border-box;
          border-radius: .12rem;
          transition: all .3s;
          @include res(padding-top,.38rem);
          @include res(padding-bottom,.3rem);
          @include res(padding-left,.4rem);
          @include res(padding-right,.4rem);
          &:hover{
            box-shadow: 0 0 .2rem rgba(0,0,0,.1);
            .item-title{
              color: $color-main;
            }
          }
          &:not(:last-child){
            @include res(margin-bottom,.3rem);
          }
          .item-tag{
            color: #729eb7;
            @include res(font-size,.14rem,(md:.24rem,sm:.34rem,xs:.24rem));
          }
          .item-title{
            color: #000;
            line-height: 1.3;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
            transition: all .3s;
            @include res(height,.6rem,(md:.9rem,sm:1.15rem,xs:.9rem));
            @include res(margin-top,.1rem);
            @include res(margin-bottom,.15rem);
            @include res(font-size,.23rem,(md:.33rem,sm:.43rem,xs:.34rem));
          }
          .item-date{
            color: #333;
            @include res(font-size,.14rem,(md:.24rem,sm:.34rem,xs:.24rem));
          }
        }
      }
    }
  }
}